var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import pubsub from '@viewlift/pubsub';
import React, { useEffect, useState } from 'react';
import Spinner from '../spinner';
import "./style.scss";
var ModuleLoader = function () {
    var _a = __read(useState(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    useEffect(function () {
        pubsub.subscribe('apploading', function (data) {
            setIsLoading(data);
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'module-loader-wrapper' }, isLoading ? React.createElement(Spinner, null) : React.createElement(React.Fragment, null))));
};
export default ModuleLoader;
