import pubsub from "@viewlift/pubsub"
const publishAppLoading = (data) => {
    pubsub.publish('apploading', data);
}
const publishRedirect = (data) => {
    pubsub.publish('redirect', data);
}
const subscribeErrorUpdate = (cb) => {
    // used by Error Banner to show all errors¯
    pubsub.subscribe('update-error-message',cb)
}
const publishErrorUpdate = (data) => {
    pubsub.publish('update-error-message',data)
}

const subscribeVideoError = (cb) => {
    pubsub.subscribe('video-error',cb)
}
const publishVideoError = (data) => {
    pubsub.publish('video-error',data)
}

const publishUserCreated = (data) => {
    pubsub.publish('user-created',data)
}

const subscribeUserCreated = (cb) => {
    pubsub.subscribe('user-created',cb)
}
const subscribeNotificationUpdate = (cb) => {
    pubsub.subscribe('notification-message',cb)
}
const publishNotificationUpdate = (data) => {
    pubsub.publish('notification-message',data)
}
const subscribeScrollIntoView = (cb) => {
    pubsub.subscribe('scroll-into-view',cb)
}
const publishScrollIntoView = (data) => {
    pubsub.publish('scroll-into-view',data)
}
const unsubscribeUserCreated = (cb) => {
    pubsub.unsubscribe('user-created',cb)
}
const enableCardFormEvent = (cb) => {
    pubsub.subscribe('card-disabled',cb)
}

export {publishAppLoading, subscribeErrorUpdate, publishErrorUpdate, subscribeVideoError, publishVideoError,publishRedirect, publishUserCreated,
     subscribeUserCreated,unsubscribeUserCreated, subscribeNotificationUpdate, publishNotificationUpdate,subscribeScrollIntoView,publishScrollIntoView, enableCardFormEvent}