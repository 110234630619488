import pubsub from "@viewlift/pubsub";
import localforage from "localforage";
import { getToken, updateGraphicsData, updateLeaderboardSse, updatePlayerNextStrokeSSE, updateSseStore } from "./helpers";
import { createStore } from "./helpers/manageSSE";
import jwtDecode from "jwt-decode";
import { BroadcastChannel } from 'broadcast-channel';
// import { getToken } from "./MFEs/web-authentication-sdk/src/helper";

let connectionState = 'IDLE';

let lastHeartbeat,abortController,intervalTimeout;
let numberOfAttempts = 0;
let isConnnectionActive = false;

const checkIfConnectionAlive = () => {
    intervalTimeout = setInterval(() => {
        let currentTime = new Date();
        if(currentTime - lastHeartbeat > 20000 && numberOfAttempts <= 5 && !isConnnectionActive){
            console.log("No heart beat from server, assuming dead connection.");
            numberOfAttempts++;
            const activeGame = sessionStorage.getItem("activeGame");
            getGameData([activeGame],true)
            initializeConnection({
              site: window.app_data?.site?.siteInternalName,
              sseBaseUrl: window?.app_data?.appcmsMain?.apiConfig?.sseBaseUrl
            });
        }else if(numberOfAttempts > 5){
          clearInterval(intervalTimeout)
          // console.error('Could not reconnect to the server after multiple attempts',`Attempts ${numberOfAttempts}`);
        }
    },10000)
}

const parseData = (live_data) => {
  try{
      return  JSON.parse(live_data)
  }catch(err){
      return live_data;
  }
}

function closeStream() {
  if (abortController) {
    abortController.abort(); // Abort the fetch request
  }
}


export const initializeConnection = async ({site,sseBaseUrl}) => {

  getToken().then(async (token) => {
    let decodeToken = jwtDecode(token);
    if (!decodeToken?.anonymousId) {window.isUserSseEvent = true}
    let userId = decodeToken?.userId;
    const res = await fetch(sseBaseUrl + `/${site}/${userId}?device=WEB`, {
      headers: {
        Authorization: token,
      },
    });

    abortController = new AbortController();
    lastHeartbeat = new Date();
    let storageName = `${window.app_data?.site?.siteInternalName}-lastTimestamp`
    localStorage.setItem(storageName, lastHeartbeat.getTime());
    checkIfConnectionAlive()

    const processEvent = async () => {
      const reader = res.body.pipeThrough(new TextDecoderStream).getReader({ signal: abortController.signal });
      const channelName = `${window.app_data?.site?.siteInternalName}-channel`
      const bc = new BroadcastChannel(channelName);
      try {
        while (true) {
          const { value, done } = await reader.read();

          if (done) {
            console.log("Connection closed / done ");
            break;
          }

          let parsed_live_data = parseData(value)
          if (parsed_live_data) {
            lastHeartbeat = new Date();
            localStorage.setItem(storageName, lastHeartbeat.getTime());
          }
          // connectSSE(parsed_live_data)
          bc.postMessage(parsed_live_data);
          isConnnectionActive  = true;
        }
      } catch (error) {
        isConnnectionActive = false
        closeStream();
        console.log("Error reading from SSE stream:", error);
      } finally {
        reader.releaseLock();
      }
    };

    processEvent();

  })
}

export const connectSSE = async (parsed_live_data) => {
  if (parsed_live_data?.payload) {
    
    let decode_payload_data = JSON.parse(window.atob(parsed_live_data?.payload))
    let notification_type = decode_payload_data.notificationType
    let payload_data_parsed = decode_payload_data && decode_payload_data?.payload && JSON.parse(decode_payload_data?.payload) || decode_payload_data?.payload
    if(notification_type == 'LIVE_GOLF_PLAYER_NEXT_STROKE_CAM' || notification_type=='CHAMPION_TOURNAMENT_STATUS_UPDATE')
    console.log(notification_type,decode_payload_data,payload_data_parsed)
    if(notification_type =='GAME_LIVE_SCORE' && decode_payload_data){
    //  return payload_data_parsed
    console.log("GAME_LIVE_SCORE",decode_payload_data?.contentMeta?.id,payload_data_parsed)
      updateSseStore({[decode_payload_data.contentMeta.id]:payload_data_parsed})
    }else if(notification_type =='GAME_STATE_CHANGE'){
      console.log("GAME_STATE_CHANGE",decode_payload_data?.contentMeta?.id,payload_data_parsed)
      updateSseStore({[decode_payload_data.contentMeta.id]:payload_data_parsed})
    }else if(notification_type =='GAME_HIGHLIGHTS_UPDATED'){
      console.log('GAME_HIGHLIGHTS_UPDATED')
      updateSseStore({[decode_payload_data.contentMeta.id]:payload_data_parsed})
    }else if(notification_type =='USER_DEVICE_ADDED'){
        console.log('USER_DEVICE_ADDED')
    }else if(notification_type =='USER_DEVICE_REMOVED'){
      pubsub.publish("DEVICE_REMOVED",payload_data_parsed)
    }else if(notification_type =='USER_DEVICE_LIMIT_REACHED'){
        console.log("USER_DEVICEUSER_DEVICE_LIMIT_REACHED_ADDED")
    }else if(notification_type =='USER_STREAM_LIMIT_REACHED'){
        console.log("USER_STREAM_LIMIT_REACHED")
    }else if(notification_type =='DEBUG_ENTITLEMENT'){
        console.log("DEBUG_ENTITLEMENT")
    }else if(notification_type =='DEBUG_DEVICE'){
        console.log("DEBUG_DEVICE")
    }else if(notification_type == 'CHAMPION_TOURNAMENT_STATUS_UPDATE'){
      updateLeaderboardSse({[payload_data_parsed?.championsData?.id] : payload_data_parsed?.golfStats})
      // pubsub.publish('golf-data-update',payload_data_parsed)
    }else if(notification_type == 'CHAMPION_SINGULAR_EVENT'){
      let data = {...decode_payload_data,payload:JSON.parse(decode_payload_data?.payload) } 
      updateGraphicsData({[payload_data_parsed?.payloadType] : data})
      pubsub.publish('graphics_data',payload_data_parsed)
    }else if(notification_type == 'LIVE_GOLF_PLAYER_NEXT_STROKE_CAM'){
      let data = {...decode_payload_data,payload:JSON.parse(decode_payload_data?.payload) } 
      updatePlayerNextStrokeSSE({[payload_data_parsed?.playerName] : data})
      pubsub.publish(payload_data_parsed?.viewliftPlayerId, payload_data_parsed)
    }else if (notification_type == "SUBSCRIPTION_CREATE_SUCCESS") {
      pubsub.publish('subscription-status-event',payload_data_parsed)
    }
    else if (notification_type == "SUBSCRIPTION_CREATE_FAILED") {
      pubsub.publish('subscription-status-event',payload_data_parsed)
    }
    else{
        console.log("no notification found to be handled")
    }
}
}


export const checkSSE = () => {
  return connectionState 
}

export const sseWatchDog = (item) => {

  const stateMap = ['default','pre','live','post','end']

  if(!window.sseWatchDogObj){
    window.sseWatchDogObj= {}
  }

  if(window.sseWatchDogObj[item.id]) return // if timer exists return 

  let currentState = item.currentState

  if(currentState != "end"){
    let nextIndex = stateMap.indexOf(currentState) + 1 
    let nextStateTime = item.states[stateMap[nextIndex]].startDateTime * 1000

    if(nextStateTime){    
        let currentTime =  Date.now();

        let timeDiff = nextStateTime - currentTime + 60000 // + 60000 to wait for 1 min before calling  

        window.sseWatchDogObj[item.id] = setTimeout(async () => {
          let sseStore = await localforage.getItem('SseStore')
          let checkGameState = sseStore[item.id]?.currentState == stateMap[nextIndex] ?  true : false
          clearTimeout(window.sseWatchDogObj[item.id]);
          if(checkGameState){
            return
          }  
          getGameData({gameId:item.id})
        },timeDiff)
      }
  }

}

export const getGameData = (gameIDs,isCallFromGamedetailModule = false) => {

  if(gameIDs.length > 0){

    let gameId = gameIDs.join(',')

    getToken()
    .then((token) => {

      let site = window?.app_data?.site.siteInternalName

      const options = {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: token 
        }
      };

      return fetch(`${window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl}/v3/content/game?ids=${gameId}&site=${site}`,options)
      .then(response => response.json())
      .then(async (response) => {
        if(response?.records?.length > 0 ){
          createStore(response.records)
        }
        if(isCallFromGamedetailModule) 
        pubsub.publish('getNonCacheResponse',response.records)
      })
      .catch(err => {
        console.error(err)
      });
    }).catch((e)=>{
      console.log('GET TOKEN ERROR:',e);
    })

  }
}

export const syncSSEStore = () => {
  let store = JSON.parse(localStorage.getItem('SseStore'))
    for (const id in store) {
      window[id] = store[id];
    }
    
}