import React, { useEffect,useState } from 'react'
import { handlePermalink } from '../../helpers';
import Cookies from 'js-cookie';
import "./index.scss"
import ViewliftIcon from '../../../images/ViewliftIcon.svg'

function index() {

    const [isAdminLoggedIn,setIsAdminLoggedIn] = useState(false)
    const [cmsMenu,setCmsMenu] = useState(false)

    let pageType = '';
    if(typeof window != "undefined"){
        pageType = window?.page_data?.page?.pageType
    }

    // for future broadcast channel

    // const showHideCMSIcon = (data) => {
    //     setIsAdminLoggedIn(data)
    // }

    useEffect(() => {
        let adminUser = Cookies.get("isUserAdmin")
        setIsAdminLoggedIn(adminUser)
    },[])

    return (
        <>
            {isAdminLoggedIn ? <div className="CmsCta">
                <div className="CmsCta-icon" onClick={() => setCmsMenu(true)}>
                    <ViewliftIcon />
                </div>
                <div className={`CmsCta-menu site-background-color ${cmsMenu ? "open" : "close"}`} onMouseLeave={() => {if(cmsMenu) setCmsMenu(false)}}>
                    <div className="CmsCta-menu-link-dropDown disabled">
                        <div>ViewLift CMS</div>
                        <ViewliftIcon />
                    </div>
                    <a href={handlePermalink()} target="_blank" className="CmsCta-menu-link-dropDown">
                        {pageType.includes("TemplatePage") || pageType.includes("DetailPage") ? "Edit Template Page" : "Edit Page"}
                    </a>
                    {pageType.includes("TemplatePage") || pageType.includes("DetailPage") ? <a target="_blank" href={handlePermalink("content")} className="CmsCta-menu-link-dropDown">
                        Edit Content
                    </a> : <></>}
                    <div className="CmsCta-menu-link-dropDown" onClick={() => setCmsMenu(false)}>
                        Hide Menu
                    </div>
                </div>
            </div> : <></>}
        </>
    )
}

export default index