import axios from "axios";
import {getToken} from "../../helper"
import pubsub from "@viewlift/pubsub";

export let logout = async() => {
  pubsub.publish('apploading',true)
  let apiBaseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl
  let site = window?.app_data?.site?.siteInternalName
  let token = await getToken(token => {return token})
    return axios({
      method: 'POST',
      url: `${apiBaseUrl}/identity/signout`,
      params: { site: site,
      platform : "web_browser"
      },
      headers: { 
        Authorization: token,
        'x-api-key': window?.xApiKey
      }
      })
}
