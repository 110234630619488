import { generateHash, generateMinifiedQuery } from "../hash";
export const scheduleGameQuery = `query gameScheduleQuery($site: String!, $limit: Int,$offset: Int, $startDate: Int, $endDate: Int, $sortBy: String, $sortOrder: SortOrder,$teamIds: [String!], $countryCode: String, $source: String, $languageCode: String,)
{
  gameSchedule(
    site: $site,
    limit: $limit,
    offset: $offset,
    startDate: $startDate,
    endDate: $endDate,
    sortBy: $sortBy,
    sortOrder: $sortOrder,
    teamIds:$teamIds,
    countryCode: $countryCode,
    languageCode: $languageCode,
    source: $source
  ) {limit
    offset
		items{
      id
      title
      currentState
      broadcaster
      gist{
        id
        site
        permalink
        title
        description
        imageGist{
          r32x9
          r16x9
          r4x3
          r3x4
          r1x1
          r9x16
        }
        contentType
        scheduleStartDate
        scheduleEndDate
      }
      homeTeam{
        id
        title
        shortName
        currentSeason
        records {
          PST {
            win
            loss
            points
            overtimeLosses
          }
          REG{
            win
            loss
            points
            overtimeLosses
          }
          PRE{
            win
            loss
            points
            overtimeLosses
          }
          PIT{
            win
            loss
            points
            overtimeLosses
          }
        }
        gist{
          title
          description
          imageGist{
            r16x9
            r4x3
            r3x4
            r1x1
          }
        }
      }
      awayTeam{
        id
        title
        shortName
        currentSeason
        records {
          PST {
            win
            loss
            points
            overtimeLosses
          }
          REG{
            win
            loss
            points
            overtimeLosses
          }
          PRE{
            win
            loss
            points
            overtimeLosses
          }
          PIT{
            win
            loss
            points
            overtimeLosses
          }
        }
        gist{
          title
          description
          imageGist{
            r16x9
            r4x3
            r3x4
            r1x1
          }
        }
      }
      displayAttribute
      states{
        live{
          startDateTime
        }
      }
      schedules{
        startDate
        endDate
        venue{
					id
          title
          gist{
            imageGist{
              r1x1              
              r16x9
            }
          }
        }
      }
      ticketUrl
      league{
        id
        title
      }
      sportsRadar{
        id
      }
      score{
        status
        type
        number
        homePoint
        awayPoint
      }
      championsData{
        id
      }
      golfStats {
        currentRound
        currentStatusIdentifier
        eventStatus
      }
    }
  }
}`

export const minifiedScheduleQuery=generateMinifiedQuery(scheduleGameQuery);
export const minifiedScheduleQuerySHA = generateHash(minifiedScheduleQuery);