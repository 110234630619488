import pubsub from '@viewlift/pubsub';
import {getGameData} from '../SSE'

export const createStore = async (data) => {
    localStorage.removeItem('SseStore')
    // let store = await localforage.getItem('SseStore')
    data?.forEach(content => {
        let store =  JSON.parse(localStorage.getItem('SseStore'))
        let obj = {
            id:content.id,
            currentState: content?.currentState,
            homePoint:content?.score?.homePoint,
            awayPoint:content?.score?.awayPoint,
            number:content?.score?.number,
            status:content?.score?.status,
            type:content?.score?.type,
            states: content?.states,
            schedules:content?.schedules
        }
        window[content.id] = obj

        if(store){

            if(store[content.id]){
                store[content.id] = {
                    ...store[content.id],
                    ...obj,
                }
            }
            else{
                store[content.id] = {
                    ...obj,
                }
            }
            localStorage.setItem('SseStore',JSON.stringify({...store}))


        }else{
            localStorage.setItem('SseStore',JSON.stringify({[content.id] : {
                ...obj
            }}))  
        }
        pubsub.publish([content.id],{...obj})
        
    });
}

export const getScores = (gameID) => {
    return window[gameID]
}

export const fetchFeaturedTrayData = async (pageData) => {
    let path = pageData?.path
    let gameIDs = extractFeaturedData(pageData?.page)
    getGameData(gameIDs)
}

const extractFeaturedData = (pageData) => {
    let gameIDs = [];
     pageData.modules.forEach((module) => {
        if(module?.layout?.type == 'VL_LivePreviewTray_01'|| module?.moduleType == "GameDetailModule"){
            module.contentData.forEach(content => {
                if(content?.gist?.contentType === "GAME"){
                    gameIDs.push(content.id)
                }     
            })
        }
    })

    return gameIDs

}    