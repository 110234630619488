import {pageQuerySHA, minifiedPageQuery} from '../server/helpers/PageSiteQuery'
import axios from 'axios'
export function fetchPageData (variables,url) {
    try{
        return axios.get(url+'?extensions={"persistedQuery":{"version":1,"sha256Hash":'+JSON.stringify(pageQuerySHA)+'}}&variables='+JSON.stringify(variables))
            .then(response => {  
                let data = response.data
                if(data.errors) {
                    return {
                        "error": true
                    }
                } else {
                    return {
                        "data": data
                    }
                }
            })
            .catch(err => console.log(err));
     }
     catch(err){
        console.error("GG", err);
     }
  }