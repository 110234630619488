import React, {ComponentPropsWithRef} from 'react'
import './style.scss'
import brandlogo from '../../../images/brandLogo.gif'

/**
 * Spinner component
 * @param {ComponentPropsWithRef<"div"> & {type?: "plain" | "brand"}} props 
 */
export default function Spinner(props) {
  const {type="brand", ...rest} = props
  const brandLoader = window?.app_data?.appcmsPlatform?.images?.desktopLoader;

  if (type==="plain") return <div className="spinner site-color" {...rest}></div>  
  return <div id='brand-loader' {...rest}><img src={brandLoader} /></div>
}

export const SocialSpinner = () => {
  return <div className="social-spinner site-color"></div>  
}
