var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Suspense, memo, useEffect } from "react";
import Modules from "./modules";
import config from './moduleMap';
import loadable from '@loadable/component';
import ModuleLoader from "./components/ModuleLoader";
import { ErrorBoundary } from 'react-error-boundary';
import { handleUserLogInState } from "./helpers";
function WrapperModule(props) {
    var siteConfig = props.siteConfig, pagedata = props.pagedata, moduleCount = props.moduleCount;
    useEffect(function () {
        handleUserLogInState();
    }, [pagedata]);
    var WatchNewModules = memo(function _watchModules(props) {
        return moduleCount > 4 ? (React.createElement(Modules, { siteConfig: siteConfig, pagedata: pagedata, offset: 4, moduleCount: moduleCount })) : (React.createElement(React.Fragment, null));
    }, function (prevProps, nextProps) { return prevProps["pathName"] === nextProps["pathName"]; });
    return (React.createElement(React.Fragment, null,
        React.createElement(ModuleLoader, null),
        pagedata && pagedata.map(function (module, index) {
            var _a;
            var moduleMap = ((_a = module === null || module === void 0 ? void 0 : module.layout) === null || _a === void 0 ? void 0 : _a.view) ? config.map[module.layout.view] : null;
            if (!moduleMap) {
                return console.log("Module not found for '".concat(moduleMap, "'"));
            }
            var Component = loadable(function () { return import("./modules/".concat(moduleMap)); }, { ssr: true });
            return (React.createElement("div", { key: module.id },
                React.createElement(ErrorBoundary, { fallback: React.createElement("p", null, "Something went Wrong") },
                    React.createElement(Suspense, null, Component && React.createElement(Component, { siteConfig: siteConfig, moduleData: module })))));
        }),
        React.createElement(WatchNewModules, __assign({}, props))));
}
var areEqual = function (prevProps, nextProps) {
    if (prevProps.pagedata === nextProps.pagedata) {
        return true;
    }
    return false;
};
export default React.memo(WrapperModule, areEqual);
