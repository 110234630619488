import pubsub from "@viewlift/pubsub";
import { fetchUserDetails, getDeviceId, getToken } from "./helpers";
// import { fetchUserDetails } from "./MFEs/Monetization-Sdk/src/fetchStore";
import { trackFbEvent, trackPixelEvent, trackUserEvent } from "./helpers/trackAnalytics";
import jwtDecode from "jwt-decode";

export const listenAnalyticEvents = async() => {
    const clientId=window.app_data.appcmsMain.id;
    const platform='Website'
    const deviceId = getDeviceId() || ""
     pubsub.subscribe('user-analytics',async({ename,data}) => {
        let token = await getToken()
        let anoynomousToken = sessionStorage.getItem('anoynomousToken')
        let decodedToken = jwtDecode(token)
        let { userId = deviceId, profileId = "guest-user" } =  await fetchUserDetails();
        let payload = [{
            siteid: clientId,
            uid: userId ? userId : decodedToken?.userId,
            pfm: platform,
            ename: ename,
            anonymousuid: anoynomousToken || null,
            ref: window.document.referrer,
            url: window.location.href,
            environment: window.env,
            etstamp:new Date().toISOString().slice(0, 19).replace('T', ' '),
            profid : profileId,
            eventdata:  data || {}
        }]
        await trackUserEvent(payload);
    })
}

export const listenScheduleFilterEvents = async(ename, data) => {
    const clientId=window.app_data.appcmsMain.id;
    const platform='Website'
        let { userId="",profileId="" } =  await fetchUserDetails();
        let payload = [{
            siteid: clientId,
            uid: userId,
            pfm: platform,
            ename: ename,
            ref: window.document.referrer,
            url: window.location.href,
            environment: window.env,
            etstamp:new Date().toISOString().slice(0, 19).replace('T', ' '),
            profid : profileId,
            eventdata:  data || {}
        }]
        await trackUserEvent(payload);
}

export const listenFBEvents = async() => {
     pubsub.subscribe('FB-analytics', ({eventType,payload,type}) => {
        let conversionApi = window?.app_data?.appcmsMain?.analytics?.conversionApi
        let conversionApiEnabled = conversionApi?.conversionApi
        let pixelID = conversionApi?.pixelID
    if (type === "FB" && conversionApiEnabled) trackFbEvent(eventType,payload)
        if (type === "PIXEL" && pixelID) trackPixelEvent(eventType,payload)
    })
}
