import { generateHash, generateMinifiedQuery } from "../hash"
export const gameInsightQuery = `query gameInsightsQuery($site: String!, $league: LeagueOption!,$srGameId:String,$srPlayerId:String,$srTeamId:String,$offset:Int,$insightType:InsightTypeOption)
{
  gameInsights(
    site: $site
    league: $league
    srGameId: $srGameId
    srPlayerId: $srPlayerId
    srTeamId: $srTeamId
    offset: $offset
    insightType: $insightType
  ) {
    results {
      id
      content
      relevancyIndex
      createdAt
      materials{
        id
        tabular{
          id
          title
          content
          displayType
          data{
            isHighlighted
            value
          }
        }
      }
    }
    limit
    offset
    vlId
  }
}`

export const minifiedGameInsightQuery=generateMinifiedQuery(gameInsightQuery)
export const gameInsightQuerySHA = generateHash(minifiedGameInsightQuery)
