import request from 'graphql-request';
import { gameInsightQuery } from './Graphql/Queries/gameInsightsQuery';
import { getToken } from './helpers';

export async function fetchGameInsightData (variables,url) {
    try{
    return getToken().then((token)=> {
        let headers = {
            "authorization": token
         }
         return request(url, gameInsightQuery, variables, headers).then((res) => {
            return res
        })
     })
   }
     catch(err){
        console.error("GG", err);
     }
  }
