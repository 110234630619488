import { generateHash, generateMinifiedQuery } from "../hash"
export const multiviewTrayQuery = `query multiviewTray(
  $site: String!,
  $ids: [String!],
  $contentType: ContentType,
  $gameId: String,
  $countryCode: String,
  $languageCode: String,
  $limit: Int!
  $offset: Int!)
{
  contentList(
      site: $site
      ids: $ids
      contentType: $contentType
      gameId: $gameId
      countryCode: $countryCode
      languageCode:$languageCode
      limit: $limit
      offset: $offset
  )
  {
      records{
          id
          gist {
              title
              contentType
              permalink
              imageGist {
                  r16x9
              }
          }
      }
  }
}`

export const minifiedMultiviewTrayQuery=generateMinifiedQuery(multiviewTrayQuery)
export const multiviewTrayQuerySHA = generateHash(minifiedMultiviewTrayQuery)
