import { generateHash, generateMinifiedQuery } from "../hash";

export const seasonGetQuery = `query ($site: String!, $seasonId: String!, $seriesId: String, $countryCode: String, $languageCode: String, $episodeLimit: Int!, $episodeOffset: Int!) {
  seasonGet(
    site: $site
    seasonId: $seasonId
    seriesId: $seriesId
    countryCode: $countryCode
    languageCode: $languageCode
    episodeLimit: $episodeLimit
    episodeOffset: $episodeOffset
  ) {
    episodeTotal
    episodeOffset
    record {
      id
      title
      episodes {
        id
        title
        gist {
          id
          site
          permalink
          title
          description
          imageGist {
            r32x9
            r16x9
            r4x3
            r3x4
            r1x1
            r9x16
          }
          primaryCategory {
            id
            title
            gist {
              imageGist {
                r32x9
                r16x9
                r4x3
                r3x4
                r1x1
                r9x16
              }
            }
          }
          contentType
          languageCode
          timezone
          featuredTag {
            id
            title
            gist {
              imageGist {
                r32x9
                r16x9
                r4x3
                r3x4
                r1x1
                r9x16
              }
            }
          }
          scheduleStartDate
          scheduleEndDate
        }
        categories {
          id
          title
          gist {
            imageGist {
              r32x9
              r16x9
              r4x3
              r3x4
              r1x1
              r9x16
            }
          }
        }
        tags {
          id
          title
          gist {
            imageGist {
              r32x9
              r16x9
              r4x3
              r3x4
              r1x1
              r9x16
            }
          }
        }
        optionalTags {
          id
          title
          gist {
            imageGist {
              r32x9
              r16x9
              r4x3
              r3x4
              r1x1
              r9x16
            }
          }
        }
        creditBlocks {
          title
          credits {
            title
          }
        }
        monetizationBadgeIdentifier
        monetizationModels {
          id
          type
        }
        plans {
          type
          planIds
          planGroupIds
        }
        pricing {
          type
          currency
          rent
          purchase {
            sd
            hd
            uhd
          }
        }
        year
        free
        streamingInfo {
          isLiveStream
          drmEnabled
          isSSAIEnabled
        }
        seriesId
        publishDate
        metadata {
          name
          value
        }
        runtime
      }
    }
  }
}`
export const minifiedSeasonQuery=generateMinifiedQuery(seasonGetQuery);
export const seasonQuerySHA = generateHash(minifiedSeasonQuery);