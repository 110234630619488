export const ANALYTICS = {
    LOGIN: 'login',
    LOGIN_INIT:'login-init',
    LOGOUT: 'logout',
    PLAN_SELECTION: 'plan-selection',
    ADD_TO_WATCHLIST: 'add-to-watchlist',
    SHARE:'share',
    SUBSCRIBE:'subscribe-now',
    GET_VERIFICATION_CODE:'get-verification-code',
    SUBMIT_VERIFICATION_CODE:'submit-verification-code',
    PAYMENT_INITIATE: 'payment-initiate',
    REMOVE_FROM_WATCHLIST:'remove-from-watchlist',
    REMOVE_ALL_WATCHLIST:'remove-all-from-watchlist',
    REMOVE_ALL_WATCHHISTORY:'remove-all-from-watch-history',
    DETAIL_PAGE_VIEW:'detail-page-view',
    HOME_PAGE_VIEW:'home-page-view',
    ADD_PAYMENT_INFO:'card-added',
    PAYMENT_SUCCCESS:'payment-success',
    PAYMENT_PENDING:'payment-pending',
    PAYMENT_FAILURE:'payment-pending',
    SEARCH:'search',
    LIKE_CONTENT:'like',
    DISLIKE_CONTENT:'dislike',
    DELETE_ACCOUNT:'delete-account',
    UPDATE_NAME:'update-name',
    SET_GAME_ALERTS:'set-game-alerts',
    CUSTOMER_FEEDBACK:'customer-feedback',
    REMOVE_DEVICE:'remove-device',
    REMOVE_ALL_DEVICES:'remove-all-devices',
    UPDATE_EMAIL:'update-email',
    UPDATE_MOBILE:'update-mobile-number',
    VERIFY_MOBILE:'verify-moble-number',
    VERIFY_EMAIL:'verify-email',
    ITEM_CLICK:'click-on-item',
    PAGE_VIEW:'page-view',
    LOGIN_FAILURE:'login-failure',
    APPLY_COUPON:'apply-code-status',
    PERSONALIZATION_POP_UP: "personalization-pop-up",
    PERSONALIZATION_EDIT_POP_UP: "personalization-edit-pop-up",
    ADD_PERSONALIZATION: "add-personalization",
    MULTIPLAYER_VIEW: "multi-player-view",
  };

  export const FB_EVENTS = {
    PAGE_VIEW: 'PageView',
    PURCHASE: 'Purchase',
    ADD_PAYMENT_INFO: 'AddPaymentInfo',
    INITIATE_CHECKOUT: 'InitiateCheckout',
    COMPLETE_REGISTRATION: 'CompleteRegistration',
    ADD_TO_CART: 'AddToCart',
    START_TRIAL: 'StartTrial',
    SUBSCRIBE: 'Subscribe',
    VIEW_CONTENT: 'ViewContent',
    ADD_TO_WISHLIST: 'AddToWishlist',
    REMOVED_FROM_WATCHLIST: 'RemovedFromWatchlist',
    SUBSCRIPTION_INITIATED: 'SubscriptionInitiated', //
    SUBSCRIPTION_COMPLETED: 'SubscriptionCompleted',
    SUBSCRIPTION_FAILED: 'SubscriptionFailed',
    TVOD_PURCHASE: 'tvod_purchase',
    TVOD_PURCHASE_COMPLETION: 'tvod_purchase_completion',
    SEARCH: 'Search',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    PLAY_STARTED: 'Play Started',
    WATCHED: 'Watched',
    SCHEDULE_FILTER: 'schedule-filter'
  };
  
  export const pixel_Events = {
    ADD_TO_CART: 'AddToCart',
    PAGE_VIEW: 'PageView',
    PURCHASE: 'Purchase',
    ADD_PAYMENT_INFO: 'Add Payment Info',
    INITIATE_CHECKOUT: 'Initiate Checkout',
    COMPLETE_REGISTRATION: 'Complete Registration',
    SUBSCRIPTION_INITIATED: "SubscriptionInitiated",
    SUBSCRIPTION_COMPLETED: 'SubscriptionCompleted'
  };