var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import './index.scss';
var Snackbar = forwardRef(function (props, ref) {
    var _a = __read(useState(false), 2), showSnack = _a[0], setShowSnack = _a[1];
    var _b = __read(useState(3000), 2), timer = _b[0], setTimer = _b[1];
    var _c = __read(useState(''), 2), message = _c[0], setMessage = _c[1];
    var _d = __read(useState('success'), 2), messageType = _d[0], setMessageType = _d[1];
    var tid = useRef(null);
    useImperativeHandle(ref, function () { return ({
        openSnackbar: function (msg, time) {
            clearTimeout(tid.current);
            setTimer(time);
            setMessage(msg === null || msg === void 0 ? void 0 : msg.message);
            setMessageType(msg === null || msg === void 0 ? void 0 : msg.type);
            setShowSnack(true);
            tid.current = setTimeout(function () {
                setShowSnack(false);
            }, timer || 3000);
        }
    }); });
    return React.createElement(React.Fragment, null, showSnack && React.createElement("div", { className: "snackbar ".concat(messageType) },
        React.createElement("div", { className: 'message-box' }, message)));
});
export default Snackbar;
