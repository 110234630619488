let config = {
    buildNumber: "2443",
    cachedAPI: "https://api-cache.staging-livgolfplus.viewlift.com",
    apiBaseUrl:"https://api.staging-livgolfplus.viewlift.com",
    apiKey:  "BkSBbok02k6RYUlCLRzI23wac0euoSfC3FP7uW2S",
    analyticsBaseUrl:"https://analytics-api.staging-livgolfplus.viewlift.com/user-beacon", 
    sseBaseUrl: "https://event.staging-livgolfplus.viewlift.com",//'https://event-livgolfplus.viewlift.com',
    wssBaseUrl: "wss://ws.staging-livgolfplus.viewlift.com",//'wss://ws-livgolfplus.viewlift.com',
    appCMSBaseUrl: "https://appcms.staging.asset.viewlift.com",
    buildAssetsUrl: "https://appcms.staging.asset.viewlift.com",
    toolsUrl:"https://staging.cms.viewlift.com",
    newToolsUrl: "https://cms.staging.viewlift.com",
    paymentWSUrl: "wss://staging-ws.viewlift.com/notification",
    internalEmbedBaseURL: "https://api-internal.staging-livgolfplus.viewlift.com",
    filesProxy: {
        directPaths: [
          '/apple-app-site-association',
          '/.well-known/apple-app-site-association',
          '/.well-known/assetlinks.json',
          '/robots.txt',
          '/ads.txt',
          '/app-ads.txt',
          '/sitemap1.xml',
          '/sitemap2.xml',
          '/sitemap3.xml',
          '/sitemap4.xml',
          '/sitemap5.xml',
          '/sitemap6.xml',
          '/sitemap7.xml',
          '/sitemap8.xml',
          '/sitemap9.xml',
          '/sitemap10.xml',
          '/offline.html',
          '/serviceWorker.js',
          '/sw.js'
        ]
      },
    env:"staging"
   }
   module.exports.config = config;
