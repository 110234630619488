import axios from 'axios';
import {config} from '../server/config';
import { multiviewTrayQuerySHA, minifiedMultiviewTrayQuery } from './Graphql/Queries/multiviewTrayQuery';

export async function fetchMultiviewTrayData (variables,url) {
   try {
      return axios.get(url+'?extensions={"persistedQuery":{"version":1,"sha256Hash":'+JSON.stringify(multiviewTrayQuerySHA)+'}}&variables='+JSON.stringify(variables))
      .then((response) => {
         //if persisted query not found 
         let res = response?.data
         if(res.errors) {
            if(res?.errors?.[0]?.message  === "PersistedQueryNotFound") {
               return cacheMultiviewTrayData(variables,url) 
            }
         }
         else{
            let data = res?.data
            return data
         }
      })
      .catch(err => {
         console.log("Multiview Error: ", err)
         return
      })
   }
   catch (err) {
      console.error("Failed to fetch multiview tray data.", err);
   }
}
   
export function cacheMultiviewTrayData (variable,url) {
   let endpoint = `${config.cachedAPI || config.apiBaseUrl}/graphql`
   let finalPayload = {
      "query": minifiedMultiviewTrayQuery,
      "variables": variable,
      "extensions": {
         "persistedQuery": {
               "version": 1,
               "sha256Hash": multiviewTrayQuerySHA
         }
      }
   }
   try{
      return axios.post(endpoint, finalPayload)
         .then(response => {  
            return fetchMultiviewTrayData(variable,url)
         })
         .catch(error => {
               if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  console.log(error.response.data);
               } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
               }
         });
   }
   catch(err){
      console.error("GG", err);
   }
}