export function parseJSON(inputString, fallback = null) {
    try {
      if (inputString && inputString !== "undefined") {
        return JSON.parse(inputString);
      }else {
        return null
      }
    } catch (e) {
      console.log("JSON Parse Error: ", e)
      return fallback;
    }
  };