// import config from '../server/config'
import localforage from 'localforage';
import { fetchPageData } from './fetchPageData';
import Cookies from "js-cookie";
import { getPreferredLanguage } from './helpers';

export default async function  FetchHook({offset,limit}) {
    const authData = await localforage.getItem('AuthenticationStore')
    const _t = Cookies.get("userStateToken") || authData?.user?.userState
    const userStateToken = isValidJson(_t) ? JSON.parse(_t) : _t
    let endpoint = window?.cachedAPI
    let parsedCountryCode = window.app_data?.countryCode || "IN" 
    let variables = {
      site: (window?.app_data?.site?.siteInternalName) || null,
      path: window?.location?.pathname,
      device: "WEB",
      includeContent: true,
      moduleLimit: limit,
      moduleOffset: offset,
      languageCode: getPreferredLanguage(),
      countryCode: parsedCountryCode,
      userState: authData && userStateToken ? userStateToken : window.btoa(JSON.stringify({ "state": ["loggedOut"] })),
      modules: []
    }
    return fetchPageData(variables,endpoint)
  
}
function isValidJson(data) {
  try {
    JSON.parse(data);
    return true;
  } catch (e) {
    return false;
  }
}