import request from "graphql-request";
import { getToken } from "./helpers";
import { pageQuery } from "../server/helpers/PageSiteQuery";
export async function fetchReplaysData (variables,url) {
    try{
    return getToken().then((token)=> {
        let headers = {
            "authorization": token
         }
         return request(url, pageQuery, variables, headers).then((res) => {
            return res
        })
     })
   }
     catch(err){
        console.error("Fetch Replays Error Message:", err);
     }
  }