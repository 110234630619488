import React from 'react';
import './index.scss';
var Spinner = function () {
    var _a, _b, _c;
    var brandLoader = (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsPlatform) === null || _b === void 0 ? void 0 : _b.images) === null || _c === void 0 ? void 0 : _c.desktopLoader;
    // return <div className="spinner site-color"></div>  
    return React.createElement("div", { id: 'brand-loader' },
        React.createElement("img", { src: brandLoader }));
};
export default Spinner;
